import './js/locale.js';
import './js/main.js';
import './js/chekin.js';
import '../../common/mr-bit/js/providers-switch.js';
import localesSwitch from "../../common/localesSwitch.js";
localesSwitch(
  'ro',
  ['en'],
  'mbBetLang',
  {en: 'English', ro: 'Română'}
);
