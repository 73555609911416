import {getCookie} from "../../cookieHelper";

const lang = getCookie('mbBetLang') ?? decodeURIComponent('ro');
const translate =  window.trans[lang];
const showText = translate?.footer.providers_switch.show || 'Show more';
const hideText = translate?.footer.providers_switch.hide || 'Hide';

const show = document.getElementById('showProviders');
const hiddenLength = document.querySelectorAll('.footer-providers__item').length - 5;

show.textContent = `${showText} ${hiddenLength}`

show.addEventListener('click', () => {
  show.classList.toggle('footer-providers__show-more_is-active');

  if( show.innerHTML.indexOf(`${hideText}`) > -1) {
    show.textContent = `${showText} ${hiddenLength}`
  } else {
    show.textContent = `${hideText}`
  }

  document.querySelectorAll('.footer-providers__item').forEach(el => {
    if (el.style.display === 'flex') {
      el.removeAttribute('style')
    } else {
      el.style.display = 'flex'
    }
  })
})
