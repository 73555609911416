const auth_ro = {
  auth: {
    registration: {
      checkin_bonus: "100% Bonus și până la 5.000 RON + 250 RG",
      duplicate_account: {
        title: "Finalizarea înregistrării este imposibilă!",
      },
      step_one: {
        email: "Adresa dvs. de e-mail",
        promo_code: "Cod promoţional",
      },
      step_two: {
        phone: "Număr de telefon",
        personal_number: "Cod Numeric Personal",
      },
      errors: {
        ro_account_duplicate: "Ești deja înregistrat pe <span>mrbit.ro</span> sub licența noastră",
        cnp_already_registered: "CNP deja înregistrat",
        phone_already_registered: "Numărul de telefon a fost deja înregistrat",
        normalized_email_not_unique: "Adresa de e-mail introdusă este deja înregistrată",
        promo_code_invalid: "Codul promo nu este valid",
        banned: "Contul dvs. este blocat. Vă rugăm să contactați asistența.",
        currency_blank: "Valuta nu poate fi necompletată",
        email_domain_restricted: "Adresă de email restricționată",
        email_format_invalid: "Adresa de e-mail este restricționată",
        no_user_with_such_email: "Adresa de e-mail este nevalidă",
        privacy_not_accepted: "Vă rugăm să acceptați politica noastră de confidențialitate",
        rules_not_accepted: "Vă rugăm să acceptați regulile cazinoului",
        invalid_recaptcha: "Vă rugăm să confirmați că nu sunteți un robot",
        nickname_blank: "Diminutivul nu poate fi necompletat",
        email_confirmation_pending: "E-mailul de confirmare a fost trimis",
        phone_format_invalid: "Tastați un număr de telefon în formatul internațional +XXXXXXXXXXX",
        blank: "Nu poate fi gol",
        birthdate_blank: "Nu poate fi gol",
        non_unique_user_attrs: "Combinația de nume, prenume și data nașterii există deja",
        age_restriction: "Persoanele sub vârsta de 21 de ani nu se pot înregistra la Mr Bit Casino",
        cnp_format_invalid: "Completează CNP-ul corect",
        cnp_age_restriction: "Din păcate, nu oferim servicii minorilor",
      }
    }
  }
};

const auth_en = {
  auth: {
    registration: {
      checkin_bonus: "100% Bonus up to 5.000 RON + 250 FS",
      duplicate_account: {
        title: "Completion of registration is not possible!",
      },
      step_one: {
        email: "Your email address",
        promo_code: "Promo code",
      },
      step_two: {
        phone: "Phone number",
        personal_number: "Personal number",
      },
      errors: {
        ro_account_duplicate: "You are already registered on <span>mrbit.ro</span> under our license",
        cnp_already_registered: "Code already registered",
        phone_already_registered: "Phone number has been already registered",
        normalized_email_not_unique: "Email address you have entered is already registered",
        promo_code_invalid: "Promo code is invalid",
        banned: "Your account is blocked. Please contact Support.",
        currency_blank: "Currency can't be blank",
        email_domain_restricted: "Restricted email address",
        email_format_invalid: "Email address is restricted",
        no_user_with_such_email: "Email address is invalid",
        privacy_not_accepted: "Please accept our privacy policy",
        rules_not_accepted: "Please accept the casino rules",
        invalid_recaptcha: "Please confirm you are not a robot",
        nickname_blank: "Nickname can't be blank",
        email_confirmation_pending: "Confirmation email has been sent",
        phone_format_invalid: "Type a phone number in international format +XXXXXXXXXXX",
        blank: "Can't be blank",
        birthdate_blank: "Can't be blank",
        non_unique_user_attrs: "This combination of name, last name and date of birth already exists",
        age_restriction: "Persons under the age of 21 may not register with Mr Bit Casino",
        cnp_format_invalid: "Enter the correct CNP number",
        cnp_age_restriction: "Unfortunately, we do not provide services to minors"
      }
    }
  }
};

export { auth_ro, auth_en };
