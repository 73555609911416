const footer_en = {
  footer: {
    category: {
      tc: 'Terms and Conditions',
      bonus_policy: 'Bonus Policy',
      promo_terms: 'Promo Terms',
      welcome_bonus_terms: 'Welcome Bonus Terms',
      responsible_gaming: 'Responsible Gaming',
      privacy_policy: 'Privacy Policy',
      anti_fraud: 'Anti Fraud',
      contact_us: 'Contact Us',
      for_affiliates: 'For Affiliates'
    },
    find_us: 'Find us on:',
    providers_switch: {
      hide: "Hide",
      show: "Show more",
    },
    all_rights_reserved: 'Mr Bit. All rights reserved.',
    gambling_addictive: 'Warning: Gambling may cause addiction.',
    jurisdiction: {
      license: 'Avento MT Limited is a company registered in accordance with Maltese Law with registration no C 82766 and operated under a license granted by the Romanian National Office for Gambling (NGO) on 1st June 2021 with licence number L1213854W001295.',
      address: 'Registered office and Physical address: Level 4B, Ta Xbiex Business Centre, Testaferrata Street, Ta’ Xbiex, XBX1403, Malta.',
    },
    responsible_gaming: 'Responsible Gaming'
  },
}
const footer_ro = {
  footer: {
    category: {
      tc: 'Termeni și Condiții',
      bonus_policy: 'Politica de Bonus',
      promo_terms: 'Termeni Promoții',
      welcome_bonus_terms: 'Termeni Bonus de Bun Venit',
      responsible_gaming: 'Joc Responsabil',
      privacy_policy: 'Politica de Confidentialitate',
      anti_fraud: 'Anti Fraudă',
      contact_us: 'Contactează-ne',
      for_affiliates: 'Pentru Afiliați'
    },
    find_us: 'Ne găsești pe:',
    providers_switch: {
      hide: "Ascunde",
      show: "Arata",
    },
    all_rights_reserved: 'Mr Bit. Toate drepturile rezervate.',
    gambling_addictive: 'Atenție: Jocurile de noroc pot provoca dependență.',
    jurisdiction: {
      license: 'Avento MT Limited este o companie înregistrată în conformitate cu legislația malteză cu numărul de înregistrare C 82766 și operată în baza unei licențe acordate de Oficiul Național pentru Jocuri de Noroc din România (ONJN) la data de 1 iunie 2021 cu numărul de licență L1213854W001295.',
      address: 'Sediul social și adresa fizică: Nivel 4B, Ta Xbiex Business Centre, strada Testaferrata, Ta’ Xbiex, XBX1403, Malta.'
    },
    responsible_gaming: 'Joc responsabil'
  }
}

export { footer_en, footer_ro };
