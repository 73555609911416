import { footer_en, footer_ro } from "../../../common/mr-bit/js/footer-locale";
import { auth_ro, auth_en } from "../../../common/mr-bit/js/chekin-locale";

window.trans = {
  en: Object.assign(footer_en, auth_en, {
    intro_captions: `
      <h1 class="intro__title">
        Betting Welcome <br/>
        bonus package <br/>
        up to <span>600 RON</span>
      </h1>
      <p>*min deposit is 20 RON, wager is x4 for bonus+deposit, min coefficient is 1,7</p>
    `,
    content: {
      welcome: {
        title: 'Welcome to Mr Bit!',
        subtitle: 'A Cyberpunk Betting',
      },
      reg_btn: 'Get Bonus',
      attention: `Attention: This offer consists of the betting bonuses <br/>for the first three deposits.`,
      top_terms_link: 'T&C apply',
    },
  }),


  ro: Object.assign(footer_ro, auth_ro,{
    intro_captions: `
      <h1 class="intro__title">
        Pachetul de Bun <br/>
        Venit pentru Pariere <br/>
        până la <span>600 RON</span>
      </h1>
      <p>*depunere min este 20 RON, rulaj pentru depozit+bonus: x4, coeficient min este 1,7</p>
    `,
    content: {
      welcome: {
        title: 'Bun venit la Mr Bit!',
        subtitle: 'Un Cazino Cyberpunk',
      },
      reg_btn: 'Obține bonus',
      attention: `Atenție: Ofertă constă din bonusuri de pariere <br/>pentru primele trei depuneri.Se aplică`,
      top_terms_link: "Termeni și Condiții",
    },
  }),

}
