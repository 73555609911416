import {getCookie, setCookie, deleteCookie} from "../../../common/cookieHelper";
import {queryParams, queryParamsLink} from "../../../common/addQueryParams";

const lang = getCookie('mbBetLang') ?? decodeURIComponent('ro');
const translate =  window.trans[lang];
const defaultRefcode = {enc: '69b89cea4c501153dea82a88d8444e35', ref: 'mb293517'};

if (getCookie('confirm_email')) {
  document.cookie = "confirm_email= ; max-age=-1";
  document.cookie = "confirm_phone= ; max-age=-1";
}

let date = new Date(Date.now() + 86400e3*365*20);
date = date.toUTCString();

const cookieEncRef = getCookie('encrypted_refcode')

function updateCookie() {
  if (queryParams.ref.length === 32) {
    setCookie('encrypted_refcode', queryParams.ref, {domain: '.mrbit.ro', expires: date });
    deleteCookie('refcode');
  } else {
    deleteCookie('encrypted_refcode');
    setCookie('refcode',queryParams.ref, {domain: '.mrbit.ro', expires: date })
  }
  if (queryParams?.s2) setCookie('s2s', queryParams?.s2s, {domain: '.mrbit.ro', expires: date });
  if (queryParams?.sub) setCookie('sub', queryParams?.sub, {domain: '.mrbit.ro', expires: date });
}

if (cookieEncRef) {
  if (queryParams.ref !== defaultRefcode.enc && queryParams.ref !== defaultRefcode.ref) {
    updateCookie();
  }
} else {
  updateCookie();
}

const URL = 'https://mrbit.ro/api'
let reCaptchaToken = null
const clickBtn = document.getElementById("btn-reg");
let isLoaded = false;

function loadingCheckin() {
  console.log('loadingCheckin start');
  window.onCheckinLoad = (checkin) => {
    console.log('oncheckinLoad 1');
    window.checkin.settings.setVariant('romania');
    window.checkin.settings.setLang(lang);
    window.checkin.settings.countries.setFeatured(["RO"]);
    window.checkin.content.overview.setOverviewReward(
        {title: translate.auth.registration.checkin_bonus, description: ''}
    );
    console.log('oncheckinLoad 2');

    checkin.dataFlow.setOnComplete(async ({ data }) => {
      const address = data.city ? `${data.city}, ${data.address1}` : data.address1;
      const params = {
        ...data,
        registrationType: 'check_in',
        address,
        completedDetailedRegistration: true,
        name: data.firstName,
        lastname: data.lastName,
        zipcode: data.postalCode,
        phone: data.phoneE164,
        ruleConfirmed: data.termsConfirmed || null,
        'g-recaptcha-response': data.recaptchaData?.token || null,
        version: 'v3',
        start_page: 'betting'
      }

      const response = await fetch(`${URL}/users/register?${queryParamsLink}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          'X-Requested-With': 'XMLHttpRequest',
        },
        body: JSON.stringify(params)
      })

      const responseJson = await response.json();

      const {
        success,
        payload: {errors, userIdHash, refcode}
      } = responseJson;

      if (success) {
        if (refcode) {
          zaraz.track("registration", {"user_id": userIdHash, "refcode": refcode});
        } else {
          zaraz.track("registration", {"user_id": userIdHash});
        }
        document.cookie = `confirm_email=${data.email}; path=/; domain=.mrbit.ro; expires=" + ${date}`;
        document.cookie = `confirm_phone=${data.phone}; path=/; domain=.mrbit.ro; expires=" + ${date}`;
        setTimeout(function() {
          document.location.replace(`https://mrbit.ro/ro/betting#signup-confirm`)
        }, 1000)
      }

      if (errors) {
        const duplicateError = errors.base?.find(err => err.includes('account_duplicate'))
        if (duplicateError) {
          return checkin.generate.dataError.custom({
            title: translate.auth.registration.duplicate_account.title,
            message: translate.auth.registration.errors.ro_account_duplicate,
            type: 'Custom Error type',
          })
        }

        const cnpError = errors.cnp?.find(err => err.includes('cnp_already_registered'))
        if (cnpError) {
          return checkin.generate.dataError.custom({
            title: translate.auth.registration.step_two.personal_number,
            message: translate.auth.registration.errors.cnp_already_registered,
            type: 'Custom Error type',
          })
        }

        const phoneError = errors.phone?.find(err => err.includes('phone_already_registered'))
        if (phoneError) {
          return checkin.generate.dataError.custom({
            title: translate.auth.registration.step_two.phone,
            message: translate.auth.registration.errors.phone_already_registered,
            type: 'Custom Error type',
          })
        }

        const emailError = errors.email?.find(err => err.includes('normalized_email_not_unique'))
        if (emailError) {
          return checkin.generate.dataError.custom({
            title: translate.auth.registration.step_one.email,
            message: translate.auth.registration.errors.normalized_email_not_unique,
            type: 'Custom Error type',
          })
        }

        const promocodeError = errors.promoCode?.find(err => err.includes('promo_code_invalid'))
        if (promocodeError) {
          return checkin.generate.dataError.custom({
            title: translate.auth.registration.step_one.promo_code,
            message: translate.auth.registration.errors.promo_code_invalid,
            type: 'Custom Error type',
          })
        }

        return checkin.generate.dataError.unknown()
      }
    })
    console.log('oncheckinLoad 3');

    checkin.dataFlow.setOnUpdate((_data, diff) => {
      return new Promise(async (resolve, reject) => {
        const { user, credentials } = diff

        if (user?.recaptcha?.token) {
          reCaptchaToken = user.recaptcha.token
        }

        const sharedValues = {
          'g-recaptcha-response': reCaptchaToken,
          version: 'v3',
          country: 'RO',
        }

        const registrationFieldValidation = async (field, body) => {
          const res = await fetch(`${URL}/users/${field}/strict_validate`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json;charset=utf-8',
              'Access-Control-Allow-Origin': '*',
              'X-Requested-With': 'XMLHttpRequest',
            },
            body: JSON.stringify(body)
          })
          return await res.json();
        }

        if (credentials?.email) {
          await registrationFieldValidation('email', {
            email: credentials.email,
            ...sharedValues,
          }).then(({success, payload}) => {
            if (success) resolve()
            if (!success && payload?.errors) {
              let errorMessage = '';
              switch (payload.errors[0]) {
                case "normalized_email_not_unique":
                  errorMessage = translate.auth.registration.errors.normalized_email_not_unique
                  break;
                case "email_domain_restricted":
                  errorMessage = translate.auth.registration.errors.email_domain_restricted
                  break;
                case "email_confirmation_pending":
                  errorMessage = translate.auth.registration.errors.email_confirmation_pending
                  break;
                case "no_user_with_such_email":
                  errorMessage = translate.auth.registration.errors.no_user_with_such_email
                  break;
                default:
                  errorMessage = translate.auth.registration.errors.email_format_invalid
              }
              reject(checkin.generate.dataError.custom({
                message: errorMessage,
                type: 'Custom Error type',
              }))
            }
          })
          reject()
        }

        if (user?.promoCode) {
          await registrationFieldValidation('promo_code', {
            promo_code: user.promoCode,
            ...sharedValues,
          }).then(({ success, payload }) => {
            if (success) resolve()
            if (!success && payload?.errors) {
              reject(checkin.generate.dataError.custom({
                message: translate.auth.registration.errors.promo_code_invalid,
                type: 'promoCodeInvalid',
                closeFlow: false,
              }))
            }
          })
          reject()
        }
      })
    })
    console.log('oncheckinLoad 4');

    window.checkin.events.setOnCloseModule(()  => {
      console.log('setOnCloseModule');
      if (clickBtn.classList.contains('loading')) {

        clickBtn.classList.remove('loading');
      }
    });
    console.log('oncheckinLoad 5');

    clickBtn.classList.remove('loading');
    isLoaded = true;
    if (clickBtn.getAttribute('data-clicked') === 'clicked') {
      console.log('oncheckinLoad 6');
      window.checkin.signUp.open();
    }
    console.log('end of checkin load fn', window.checkin);
  }
}

console.log('before loading checkin function');
loadingCheckin();
console.log('after loading checkin function');

clickBtn.addEventListener('click', () => {
  if (isLoaded) {
    console.log('click-btn loaded');
    window.checkin.signUp.open();
  } else {
    console.log('click-btn not loaded');
    loadingCheckin();
  }
});
