import {getCookie, setCookie, deleteCookie} from "./cookieHelper";
export default function localesSwitch(defaultLang, otherLanguages, cookieLangName, currentLangNames = null) {

  function browserLocale() {
    let lang;
    if (navigator.languages && navigator.languages.length) {
      lang = navigator.languages[0]
    } else if (navigator.userLanguage) {
      lang = navigator.userLanguage
    } else {
      lang = navigator.language
    }
    return lang;
  }

  let currentLang;
  const TEN_YEARS = 3600 * 1000 * 24 * 365 * 10;
  let date = new Date(Date.now() + TEN_YEARS);
  date = date.toUTCString();

  if (getCookie(cookieLangName)) {
    currentLang = getCookie(cookieLangName) ?? defaultLang;
  } else {
    currentLang = otherLanguages.find(el => browserLocale()  === el) ?? defaultLang;
    setCookie(cookieLangName, currentLang, {'expires': date})
  }

  const localeToggler = document.getElementsByClassName('LanguageSelector-lang')[0];
  const localeTogglerButton = localeToggler.getElementsByClassName('LanguageSelector-view')[0];
  const langToggler = document.getElementsByClassName('CountryList-list')[0];

  document.addEventListener('click', (e) => {
    if (!e.composedPath().includes(localeToggler) && localeTogglerButton.classList.contains('LanguageSelector-viewIsOpen')) {
      localeTogglerButton.classList.remove('LanguageSelector-viewIsOpen');
    }
  })

  localeToggler.addEventListener('click', (e) => {
    localeTogglerButton.classList.toggle('LanguageSelector-viewIsOpen');
  })

  langToggler.addEventListener('click', (e) => {
    setCookie(cookieLangName, e.target.dataset.lang, {'expires': date});
    window.scrollTo({
      top: 0,
    });
    location.reload();
  })

  document.querySelector(`[data-lang=${currentLang}]`)
    .closest('.CountryList-listItem')
    .classList
    .add('CountryList-listItemCurrent');

  function getDatasetValue(obj, string) {
    let tmp = string.split('.');
    let str;

    for (let i of tmp) {
      let s = tmp.filter(e => e !== i);
      if (typeof obj[i] === 'object' && tmp.length > 1) {
        str = getDatasetValue(obj[i], s.join('.'));
        break;
      } else {
        str = obj[i];
      }
    }

    return str;
  }

  const translations = window.trans;

  document.querySelectorAll('[data-translate]').forEach(el => {
    el.innerHTML = getDatasetValue(translations[currentLang], el.dataset.translate)
    el.classList.toggle(`${currentLang}`)
  });

  document.querySelectorAll('[data-translatecontent]').forEach(el => {
    el.setAttribute("content",  getDatasetValue(translations[currentLang], el.dataset.translatecontent));
  });

  const currentLangView = document.getElementsByClassName('LanguageSelector-current')[0];
  currentLangView.innerText = currentLangNames ? currentLangNames[currentLang] : currentLang
  ;
}


